import { saveData } from "../api"
import { useState, useRef, useEffect } from 'react'


class Queue {
    _store: string[][] = []
    enqueue = (task: string[]) => this._store.push(task)
    dequeue = () => this._store.shift()
    length = () => this._store.length
}


export const useSerialProcessor = (asyncProcessingCallback: any, asyncEndCallback: any) => {
    const updateQueue = useRef(new Queue())
    const isReady = useRef(true)

    const addEvent = (params: string[]) => {
        updateQueue.current.enqueue(params);
    };

    const processEvents = async () => {
        if (isReady.current && updateQueue.current.length() > 0) {
            const params = updateQueue.current.dequeue() as string[];
            isReady.current = false

            await asyncProcessingCallback(params)

            isReady.current = true
            processEvents()
        } else if (isReady && updateQueue.current.length() === 0) {
            await asyncEndCallback()
        }

    }

    return {
        process: (params: string[]) => {
            addEvent(params)
            processEvents()
        }
    }
}

const useSavingManager = (updateData: any, initialValues: Record<string, any>) => {
    const [savingKey, setSavingKey] = useState('')
    const savedValues = useRef<Record<string, any>>(initialValues)

    useEffect(() => {
        savedValues.current = initialValues
    }, [initialValues])

    const updateItem = ([field, value]: string[]) => {
        const [key, sortOrder] = field.split('#-#')
        const newValue = typeof (value) === 'object' ? value : { value }

        setSavingKey(key)
        return saveData({
            data: [{
                key,
                sortOrder,
                ...newValue
            }]
        })
            .then(() => {
                setSavingKey('')
                savedValues.current[field] = value
            })

    }

    const { process } = useSerialProcessor(updateItem, updateData)

    return { savingKey, savedValues, updateItem: process }

}

export default useSavingManager