import axios from 'axios'
export const BASE_API_URL = 'https://drill-configurator-api.code4nord.com'
export const API_URL = `${BASE_API_URL}/api/`

axios.defaults.baseURL = API_URL
axios.defaults.withCredentials = true

const getHeaders = (language: string) => (
    {
        headers: {
            'Accept-Language': language
        }
    }
)
export const login = (username: string) => {
    return axios.post('authentication/login', {
        username
    })
}

export const logout = () => {
    return axios.post('authentication/logout')
}

export const getData = (language: string) => axios.get('drill/get-data', getHeaders(language))

export const saveData = (data: any) => axios.post('drill/save-data', data)

export const resetData = () => axios.post('drill/reset-data')

const upload = (url: string, data: any) => axios.post(url, data, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})
export const uploadData = (data: any) => upload('drill-file', data)

export const deleteFile = (variableKey: string) => axios.delete(`drill-file/${variableKey}`)

export const uploadTemplate = (data: any) => upload('drill/upload-template', data)
